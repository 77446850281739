import React, { useState, useEffect } from "react";
import { Grid, Card } from "@material-ui/core";
import DataService from "../../services/api/data_services";
import Layout from "../../components/layouts/layout";
import MobileSmoothSlider from "../../components/MobileSmoothSlider";
import Infoview from "../../templates/InfoView";
import Megaview from "../../templates/MegaView";
import Miniview from "../../templates/MiniView";
import Button from "../../components/Button";
import RightArrow from "../../assets/Icons/go.svg";
import clutchLogo from "../../assets/Images/WhatWeDo/clutch-co-vector-logo.svg";
import RightArrowWhite from "../../assets/Icons/accordian_white.svg";
import LeftGrid from "../../templates/LeftGrid";
import { BASE_SITE_URL } from "../../shared/constants";
//Mini Image Banner1
import MiniImageBanner1 from "../../templates/MiniImageBanner1";
import MiniImageBannerBgImg from "../../assets/Images/WhatWeDo/who_we_work_with.png";
//Image Banner
import ImageBanner from "../../templates/ImageBanner";
import MobileMovieSlider from "../../components/MobileMovieSlider";
//Image Banner
import ourTraits from "../../assets/Images/why digiryte/WhyDigiryteforHealthcare@2x.png";
//Icon Card List
import IconCardList from "../../templates/IconCardList";
import IconCardListIcon1 from "../../assets/Images/Industry/RetailAndEcommerce/AI.svg";
import IconCardListIcon2 from "../../assets/Images/Industry/RetailAndEcommerce/Augmented reality.svg";
import IconCardListIcon3 from "../../assets/Images/Industry/RetailAndEcommerce/Ambient commerce.svg";
import IconCardListIcon5 from "../../assets/Images/Industry/RetailAndEcommerce/Chatbots.svg";
import IconCardListIcon6 from "../..//assets/Images/Industry/RetailAndEcommerce/Conversational e-commerce.svg";
import IconCardListIcon4 from "../../assets/Images/Industry/RetailAndEcommerce/Online to Offline (O2O).svg";
//PlainCard
import PlainCard from "../../components/SixPlainCard";
import hoverCardImg1 from "../../assets/Images/Industry/RetailAndEcommerce/easier connections.svg";
import hoverCardImg5 from "../../assets/Images/Industry/RetailAndEcommerce/Smooth Operations.svg";
import hoverCardImg3 from "../../assets/Images/Industry/RetailAndEcommerce/Convenience.svg";
import hoverCardImg4 from "../../assets/Images/Industry/RetailAndEcommerce/Higher Revenue.svg";
import hoverCardImg2 from "../../assets/Images/Industry/RetailAndEcommerce/BetterCustomerExperience.svg";
import hoverCardImg6 from "../../assets/Images/Industry/RetailAndEcommerce/Improved Customer Retention.svg";

//Mini Image Banner
import MiniImageBanner from "../../templates/MiniImageBanner";
import MiniImgBnrBgImg from "../../assets/Images/contact_us_bg.png";
import Typography from "@material-ui/core/Typography";
import BackArrowIcon from "../../assets/Images/accordian.svg";

//Two column contact info
import TwoColumnContactInfo from "../../templates/TwoColumnContactInfo";
import Clm1Img from "../../assets/Icons/phone.svg";
import Clm2Img from "../../assets/Icons/mail.svg";
import ogimage from "../../assets/Images/ogImages/Retail_and_E-commerce@2x.png";
import ImageBnr from "../../assets/Images/Industry/RetailAndEcommerce/Retail_and_E-commerce@2x.png";
// Base url
import clientMovieSliderImage from "../../assets/Images/Industry/HealthCare/OurHealthcareexpertiseincludes_mobile@2x.png";

const RetailAndEcommerce = () => {
  const [page, setPage] = useState(1);
  const [articleData, setArticleData] = useState([]);
  const [megaviewData, setMegaViewData] = useState([]);
  const [clientStoryData, setClientStoryData] = useState([]);
  const [clientStoryRawData, setClientStoryRawData] = useState([]);
  const [articleRawData, setArticleRawData] = useState([]);
  const [isHover, setIsHover] = useState(false);
  const [articlePage, setArticlePage] = useState(1);
  const [articleLimit, setArticleLimit] = useState(6);
  const [whitePaperRawData, setWhitePaperRawData] = useState([]);
  const [whitePaperPage, setWhitePaperPage] = useState(1);
  const [whitePaperLimit, setWhitePaperLimit] = useState(1);
  const toggleHover = () => {
    setIsHover(!isHover);
  };

  useEffect(() => {
    DataService.getData(
      `/admin/api/all-industries?limit=${whitePaperLimit}&page=${whitePaperPage}&type=White Paper&industry_type=Retail and E-commerce`
    ).then((response) => {
      setMegaViewData([...megaviewData, ...response.data]);
      setWhitePaperRawData(response);
    });
  }, [whitePaperPage]);
  useEffect(() => {
    DataService.getData(
      `/admin/api/all-industries?limit=3&page=${articlePage}&type=Article&industry_type=Retail and E-commerce`
    ).then((response) => {
      setArticleData([...articleData, ...response.data]);
      setArticleRawData(response);
    });
  }, [articlePage]);
  useEffect(() => {
    DataService.getData(
      `/admin/api/all-industries?limit=3&page=${page}&type=Client Story&industry_type=Retail and E-commerce`
    ).then((response) => {
      setClientStoryData([...clientStoryData, ...response.data]);
      setClientStoryRawData(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const quotedMiniCardData = {
    title: "Web Dev & Automation for E-Commerce Subscription Service",
    heading:
      "It was clear that they wanted to deliver the best product possible.",
    content: "Co-founder, Vapewunder",
    footerContent: (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div>Authentic client review from</div>
        <div style={{ paddingLeft: "5px" }}>
          <img src={clutchLogo} alt="Clutch" height="50px" />
        </div>
      </div>
    ),
  };

  const miniviewDataArticle = {
    height: "490",
    service: "machineLearning",
    title: "Let's Talk",
    subTitle: "We'd love to help with your product.",
    variant: "contained",
    label: "GET IN TOUCH",
    tooltip: "yes",
    tip: "GET IN TOUCH",
    customClass: "miniCrdBgImg-main-border",
    route: "/contact-us",
  };

  /**
   * Image Banner // HIDING TEMPORATILY
   */
  const ImageBannerData = {
    bnrImg: ImageBnr,
    altImg: ImageBnr,
    autoWidth: true,
    // component:'Who',
    bnrImgTitle: <> Retail and E-commerce</>,
    bnrImgSubTitle: "Industries",
    servicesColumn1: {
      data: [
        {
          service: "Startups",
          link: "/who-we-work-with/startups",
        },
        {
          service: "Education",
          link: "/who-we-work-with/education",
        }, // this is temporary
        // {
        //   service: "Finance",
        //   link: "/who-we-work-with/finance"
        // },
        // {
        //   service: "Hospitality",
        //   link: "/who-we-work-with/hospitality"
        // },
        // {
        //   service: "Not-For-Profit",
        //   link: "/who-we-work-with/not-for-profit"
        // }
      ],
    },
    servicesColumn2: {
      data: [
        {
          service: "Healthcare",
          link: "/who-we-work-with/healthcare",
        },
        {
          service: "Finance",
          link: "/who-we-work-with/finance",
        }, // this is temporary
        // {
        //   service: "Education",
        //   link: "/who-we-work-with/education"
        // },
        // {
        //   service: "Real Estate & PropTech",
        //   link: "/who-we-work-with/real-estate-and-proptech"
        // },
      ],
    },
    servicesColumn3: {
      data: [
        {
          service: "Retail and E-commerce",
          link: "/who-we-work-with/retail-and-e-commerce",
        },
        // {
        //   service: "Media and Publishing",
        //   link: "/who-we-work-with/media-and-publishing"
        // },
        // {
        //   service: "Public Sector",
        //   link: "/who-we-work-with/public-sector"
        // }
      ],
    },
    // servicesColumn1: {
    //   data: [
    //     {
    //       service: "Startups",
    //       link: "/who-we-work-with/startups"
    //     },
    //     {
    //       service: "Finance",
    //       link: "/who-we-work-with/finance"
    //     },
    //     {
    //       service: "Hospitality",
    //       link: "/who-we-work-with/hospitality"
    //     },
    //     {
    //       service: "Not-For-Profit",
    //       link: "/who-we-work-with/not-for-profit"
    //     }
    //   ]
    // },
    // servicesColumn2: {
    //   data: [
    //     {
    //       service: "Healthcare",
    //       link: "/who-we-work-with/healthcare"
    //     },
    //     {
    //       service: "Education",
    //       link: "/who-we-work-with/education"
    //     },
    //     {
    //       service: "Real Estate & PropTech",
    //       link: "/who-we-work-with/real-estate-and-proptech"
    //     },
    //   ]
    // },
    // servicesColumn3: {
    //   data: [
    //     {
    //       service: "Retail and E-commerce",
    //       link: "/who-we-work-with/retail-and-e-commerce"
    //     },
    //     {
    //       service: "Media and Publishing",
    //       link: "/who-we-work-with/media-and-publishing"
    //     },
    //     {
    //       service: "Public Sector",
    //       link: "/who-we-work-with/public-sector"
    //     }
    //   ]
    // }
  };

  const InfoviewData = {
    route: "/what-we-do",
    color: "primary",
    tooltip: "yes",
    width: "11%",
    InfoviewTitle: (
      <>
        {" "}
        Let’s Build <span style={{ textTransform: "none" }}>a</span> World Class
        <br />
        <span className="red-line3letter red-3letter-mbl">Cus</span>tomer
        experience
      </>
    ),
    InfoviewContent: (
      <>
        The way we procure is changing. Modern retailers are shifting from
        physical to digital, from owning to sharing, from shopping for products
        to buying tailored solutions, recipients of values to recipients of
        co-owners. Our retail strategies are being influenced by a new
        generation of consumers, who click and shop in a different way, where
        digital penetration is a differentiator, not a constraint. <br />
        <br />
        The retail space has a unique set of challenges and questions that must
        be considered before any development begins. Each product, sector, and
        business is unique and as such so are the design and development
        challenges we run into and all must be unified within your unique brands
        and company needs. Digiryte will tweak the value of your business in the
        Retail and Ecommerce Marketplace to be successful in this digital world.
      </>
    ),
  };

  /**
   * TwoColumnContactInfo
   */

  const TwoColumnContactInfoData = {
    Clm1Img: Clm1Img,
    Clm1Title: "Phone:",
    Clm1SubTitle: "+44 (0) 161 706 7983",
    Clm2Img: Clm2Img,
    Clm2Title: "Email:",
    Clm2SubTitle: "hello@digiryte.com",
  };

  /**
   * Hover card button
   */

  const PlainCardData = [
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: "Easier Connections with Customers",
      HoverCardWithBtnsubtitle: " ",
      HoverCardWithBtnIcon: hoverCardImg1,
      HoverCardWithBtnIconAlt: "Easier Connections with Customers",
      HoverCardIconClass: "plain-card-button-main-img",
    },
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: "Smooth Operations",
      HoverCardWithBtnIcon: hoverCardImg5,
      HoverCardWithBtnIconAlt: "Smooth Operations",
      HoverCardIconClass: "plain-card-button-main-img",
      HoverCardWithBtnsubtitle: " ",
    },
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: "Convenience",
      HoverCardWithBtnIcon: hoverCardImg3,
      HoverCardWithBtnIconAlt: "Convenience",
      HoverCardIconClass: "plain-card-button-main-img",
      HoverCardWithBtnsubtitle: " ",
    },
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: (
        <>
          Higher <br /> Revenue
        </>
      ),
      HoverCardWithBtnIcon: hoverCardImg4,
      HoverCardWithBtnIconAlt: "Higher Revenue",
      HoverCardIconClass: "plain-card-button-main-img",
      HoverCardWithBtnsubtitle: " ",
    },
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: (
        <>
          Better <br /> Customer Experience
        </>
      ),
      HoverCardWithBtnIcon: hoverCardImg2,
      HoverCardWithBtnIconAlt: "Better Customer Experience",
      HoverCardIconClass: "plain-card-button-main-img",
      HoverCardWithBtnsubtitle: " ",
    },
    {
      // HoverCardWithBtnCustomClass: "hover-card-bg-odd",
      HoverCardWithBtntitle: "Improved Customer Retention",
      HoverCardWithBtnIcon: hoverCardImg6,
      HoverCardWithBtnIconAlt: "Improved Customer Retention",
      HoverCardIconClass: "plain-card-button-main-img",
      HoverCardWithBtnsubtitle: " ",
    },
  ];
  //  Mobile movie slider
  const mobMovieSlider = {
    imageUrl: ourTraits,
  };

  //Movie Slider
  const movieSlider = {
    imageUrl: clientMovieSliderImage,
  };
  /**
   * Icon Card List Data
   */

  const IconCardListData = [
    {
      icon: IconCardListIcon1,
      altTxt: "Artificial Intelligence (AI)",
      heading: (
        <>
          Artificial Intelligence <br />
          (AI)
        </>
      ),
      headingclassname: "iconhead",
      classname: "iconimgclass",
    },
    {
      icon: IconCardListIcon2,
      altTxt: "14 years in Healthcare IT Industry",
      heading: (
        <>
          Augmented Reality <br />
          (AR)
        </>
      ),
      headingclassname: "iconhead",
      classname: "iconimgclass",
    },
    {
      icon: IconCardListIcon3,
      altTxt: "Ambient commerce",
      heading: "Ambient commerce",
      headingclassname: "iconhead",
      classname: "iconimgclass",
    },
    {
      icon: IconCardListIcon4,
      altTxt: "Online to Offline (O2O)",
      heading: <>Online to Offline (O2O)</>,
      headingclassname: "iconhead",
      classname: "iconimgclass",
    },
    {
      icon: IconCardListIcon5,
      altTxt: "Chatbots",
      heading: <>Chatbots</>,
      headingclassname: "iconhead",
      classname: "iconimgclass",
    },
    {
      icon: IconCardListIcon6,
      altTxt: "Conversational e-commerce",
      heading: (
        <>
          Conversational
          <br />
          e-commerce
        </>
      ),
      headingclassname: "iconhead",
      classname: "iconimgclass",
    },
  ];
  const MiniImageBanner1Data1 = {
    title: (
      <span>
        <span className="black-line3letter">How</span> we do it
      </span>
    ),
    titleClass: "mini-image-banner-title-area3 min-title-header",
    image: MiniImageBannerBgImg,
    altTxt: "How we do it",
    btnClassName: "image-banner-resize-btn",
    btnLabel: "TAKE A LOOK",
    btnVariant: "contained",
    btnColor: "primary",
    route: "/how-we-do-it",
    underline: false,
    tooltip: "yes",
    tip: "TAKE A LOOK",
    underlineClass: "black-line",
  };
  const MiniImageBanner3Data = {
    Id: "minibanner1",
    MiniImageBannerBgImg: MiniImgBnrBgImg,
    MiniImageBannerAltTxt: "Contact Us to estimate your product",
    HoverLinkContent: "Contact Us",
    NearHoverLinkContent: " to ",
    PlainContent: "estimate your product",
    route: "/contact-us",
  };
  return (
    <Layout
      seo={{
        title: "Digiryte | Retail & Ecommerce | Shift from physical to digital",
        ogtitle:
          "Digiryte | Retail & Ecommerce | Shift from physical to digital",
        description:
          "Modern retailers are shifting from physical to digital, from shopping for products to buying tailored solutions, recipients of values to recipients of co-owners.",
        ogimage: ogimage,
        ogurl: `${BASE_SITE_URL}`,
      }}
    >
      <ImageBanner imageBannerData={ImageBannerData} />
      <div className="mt-70 body-container">
        <Button
          icon={
            <img
              className="effect-btn-svgicon"
              src={BackArrowIcon}
              alt="click"
            />
          }
          customClassName="effect-btn"
          className="mui-button-font"
          tip="What we do"
          routepath="/who-we-work-with"
        />
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: "600",
            marginTop: "-42px",
            marginLeft: "40px",
          }}
        >
          Who we work with
        </Typography>
      </div>
      <div className="mt-30">
        <Infoview InfoviewData={InfoviewData} />
      </div>
      <div className="body-container mt-70">
        <h1 className="title-header">
          Our Retail And E-commerce <br />{" "}
          <span className="red-line3letter red-3letter-mbl">Exp</span>ertise
          Creates
        </h1>
      </div>
      <Grid
        className="mt-50 body-container hide-in-mobile"
        container
        direction="row"
        spacing={3}
      >
        {PlainCardData.map((item, index) => (
          <Grid className="appsgrid" key={index} item md={4}>
            <Card className="appsgridshadow">
              <PlainCard
                customHeight="140"
                HoverCardWithBtnCustomClass={item.HoverCardWithBtnCustomClass}
                HoverCardWithBtntitle={item.HoverCardWithBtntitle}
                HoverCardWithBtnIcon={item.HoverCardWithBtnIcon}
                HoverCardWithBtnIconAlt={item.HoverCardWithBtnIconAlt}
                HoverCardIconClass={item.HoverCardIconClass}
                HoverCardWithBtnsubtitle={item.HoverCardWithBtnsubtitle}
              />
            </Card>
          </Grid>
        ))}
      </Grid>
      <Grid className="show-only-in-mobile">
        <MobileSmoothSlider movieSlider={movieSlider} />
      </Grid>
      <div className="mt-50">
        <MiniImageBanner miniImageBannerData={MiniImageBanner3Data} />
      </div>
      <div className="body-container mt-70">
        <h1 className="title-header">
          Our Retail and E-commerce <br />{" "}
          <span className="red-line3letter red-3letter-mbl">Cap</span>abilities
          Includes
        </h1>
        <div className="mt-50 mbl-hide">
          <IconCardList IconCardListData={IconCardListData} />
        </div>
        <MobileMovieSlider movieSlider={mobMovieSlider} slideid="slide1" />
      </div>
      {clientStoryData && clientStoryData.length > 0 && (
        <div className="mt-70 body-container">
          <h2 className="title-header">
            {" "}
            <span className="red-line3letter red-3letter-mbl">Cli</span>ent
            Stories
          </h2>
          <div className="mt-50"></div>
        </div>
      )}
      {clientStoryData && clientStoryData.length > 0 && (
        <div className="mt-50">
          <Miniview
            miniviewData={clientStoryData}
            miniviewData2={miniviewDataArticle}
            quotedMiniCardData={quotedMiniCardData}
            navigateComponent={<LeftGrid />}
          />
        </div>
      )}
      {clientStoryRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setPage(page + 1);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="Load More"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="Load More"
              tip="Load More"
              tooltip="yes"
            />
          </div>
        </div>
      )}
      {megaviewData && megaviewData.length > 0 && (
        <div className="mt-70 body-container">
          <Megaview MegaviewData={megaviewData} />
        </div>
      )}
      {whitePaperRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setWhitePaperPage(whitePaperPage + 1);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE ARTICLES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="LOAD MORE"
              tip="LOAD MORE"
            />
          </div>
        </div>
      )}
      <div className="mt-70"></div>
      <div className="mt-20">
        <MiniImageBanner1
          id="one"
          MiniImageBanner1Data={MiniImageBanner1Data1}
        />
      </div>
      {articleData && articleData.length > 0 && (
        <div className="mt-70">
          <div className="body-container">
            <h3 className="title-header ttc">
              <span className="red-line3letter">Rec</span>ommended Reading
            </h3>
          </div>
          <div className="mt-50">
            <Miniview
              miniviewData={articleData}
              total={articleData.total}
              miniviewData2={miniviewDataArticle}
              quotedMiniCardData={quotedMiniCardData}
            />
          </div>
        </div>
      )}
      {articleRawData.has_more && (
        <div style={{ width: "100%", textAlign: "center" }}>
          <div
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            className="mt-60"
            role="presentation"
            onClick={() => {
              setArticlePage(articlePage + 1);
              setArticleLimit(3);
            }}
            style={{ display: "inline-block" }}
          >
            <Button
              icon={
                <img
                  className="effect-btn-svgicon"
                  src={!isHover ? RightArrow : RightArrowWhite}
                  alt="MORE ARTICLES"
                />
              }
              customClassName="info-view-resize-btn"
              color="primary"
              variant="outlined"
              label="LOAD MORE"
              tip="LOAD MORE"
            />
          </div>
        </div>
      )}
      <div>
        <TwoColumnContactInfo
          TwoColumnContactInfoData={TwoColumnContactInfoData}
        />
      </div>
    </Layout>
  );
};

export default RetailAndEcommerce;
